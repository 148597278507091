/* eslint-disable no-useless-escape */
import React, { useEffect, useRef, useState } from "react";
import img from "../../assets/images/nr1.jpg";
import PasswordInput from "../../components/form/password-input";
import { Button, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import MTNLogo from "../../assets/images/mtnyellow.png";
import Firebase from "../../core/firebase";
import DataProvider from "../../services";
import images from "../../assets/images/poweredbymtnwhite.png";
import med from "../../assets/images/md_logo.png";
import medW from "../../assets/images/logo.png";
import {
  firebasePhoneNumberSigninError,
  firebaseSigninErrorMessage,
} from "../../utils";
import { useAppStore } from "../../store";
import EmailOrPhoneInput from "../../components/form/email-number-input";
import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import CustomInput from "../../components/form/custom-input";
import EmailInput from "../../components/form/email-input";
import DobInput from "../../components/form/dob-input";
import CustomPickerList from "../../components/form/custom-picker-list";

type NotificationType = "success" | "info" | "warning" | "error";

export default function Login() {
  const { setToken, setUser } = useAppStore();
  const [api, contextHolder] = notification.useNotification();
  const navigation = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult | null>(null);
  const [name, setName] = useState("");
  const [gender, setGender] = useState<string | null>(null);
  const [dob, setDob] = useState<any>(null);
  const [email, setEmail] = useState<string>("");

  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  useEffect(() => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = Firebase.recaptchaVerifier();
    }

    // Cleanup reCAPTCHA when the component unmounts
    return () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
        recaptchaVerifierRef.current = null;
      }
    };
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  // Helper function to determine if the input is an email or phone number
  const isEmail = (input: string) => {
    // eslint-disable-next-line
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(input);
  };

  async function onSubmit() {
    if (contact.length < 5) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Contact field must not be empty! Type your email address or phone number."
      );
    }
    if (password.length < 6) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Password must be 6 or more characters!"
      );
    }

    setLoading(true);
    if (isEmail(contact)) {
      await Firebase.loginWithEmail(contact, password)
        .then(async (result) => {
          const user = result.user;
          await DataProvider.setToken(await user.getIdToken());
          const profile = await DataProvider.getProfile();
          const userWith = { ...profile.data, timenow: new Date() };
          setUser(userWith);
          setToken({ token: await user.getIdToken() });

          setLoading(false);
          navigation("/main");
        })
        .catch(async (e) => {
          const err = firebaseSigninErrorMessage(e);
          openNotificationWithIcon("error", "Error", err);
          setLoading(false);
        });
    } else {
      // auth.settings.appVerificationDisabledForTesting = true;
      if (!isOtpSent) {
        sendOtp();
      } else {
        verifyOtp();
      }
    }
  }

  const sendOtp = async () => {
    try {
      if (!recaptchaVerifierRef.current) return;
      setLoading(true);
      const confirmationResult = await Firebase.loginWithPhoneNumber(
        contact,
        recaptchaVerifierRef.current
      );
      setConfirmationResult(confirmationResult);
      setIsOtpSent(true); // OTP sent, change flow
      setLoading(false);
      openNotificationWithIcon(
        "success",
        "OTP Sent",
        "Please check your phone for the verification code."
      );
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Error",
        error.message ? error.message : "Failed to send OTP. Please try again."
      );
      recaptchaVerifierRef.current?.clear();
      setLoading(false);
    }
  };

  const verifyOtp = async () => {
    if (otp.length < 6) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Please enter the 6-digit OTP!"
      );
    }
    setLoading(true);
    try {
      if (!confirmationResult) return;
      const result = await confirmationResult.confirm(otp);
      const user = result.user;
      await DataProvider.setToken(await user.getIdToken());
      setToken({ token: await user.getIdToken() });
      const { data } = await DataProvider.getProfile();

      if (!data.fullname || !data.dateOfBirth) {
        setLoading(false);
        setIsVerified(true);
        setIsNewUser(true);
        return;
      }

      const userWith = { ...data, timenow: new Date() };
      setUser(userWith);

      setLoading(false);
      navigation("/main");
    } catch (error: any) {
      const err = firebasePhoneNumberSigninError(error);
      openNotificationWithIcon("error", "Error", err);
      setLoading(false);
    }
  };

  const onNewUserSave = async () => {
    setLoading(true);
    const payload = {
      name,
      gender,
      dateOfBirth: dob,
    };
    try {
      const { data } = await DataProvider.updateProfile(payload);
      // const { data } = await DataProvider.getProfile();
      const userWith = { ...data, timenow: new Date() };
      setUser(userWith);
      setLoading(false);
      setIsVerified(false);
      setIsNewUser(false);
      navigation("/main");
    } catch (error: any) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Failed, Couldn't save your data. Kindly try again."
      );
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full">
      <div
        className="h-screen lg:hidden flex justify-center items-center w-full bg-cover bg-[#1A94ECB5] bg-blend-overlay"
        style={{ backgroundImage: `url(${img})` }}
      >
        {isNewUser && isVerified ? (
          <div className="min-h-[60%] w-full rounded-t-3xl bg-white flex flex-col justify-center items-center pb-4">
            <div className="w-[80%] my-4">
              <p className="text-xl font-medium">Personal Information</p>
            </div>
            <div className="w-[80%]">
              <CustomInput
                title="Full name"
                // eslint-disable-next-line
                style="md:h-12 h-10"
                placeholder="Enter your full name"
                value={name}
                onChangeText={setName}
              />
              <DobInput dob={dob} setDob={setDob} />
              <CustomPickerList
                title="Gender"
                options={["male", "female"]}
                value={gender}
                onValueChange={setGender}
              />
              <Button
                type="primary"
                size="large"
                onClick={onNewUserSave}
                className="bg-[#1A94EC] w-full text-white"
              >
                {loading ? "Saving..." : "Save"}
              </Button>

              <div className="absolute bottom-5 right-9 flex">
                <img src={med} className="h-8 mx-4" alt="" />
                <img src={MTNLogo} className="h-8" alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="min-h-[60%] w-full rounded-t-3xl bg-white flex flex-col justify-center items-center pb-4">
            <div className="w-[80%] my-4">
              <h1 className="md:text-3xl text-xl font-bold leading-10">
                Login
              </h1>
              <p className="md:text-xl text-base text-gray-500">
                Sign in to your account.
              </p>
            </div>
            <div className="w-[80%]">
              <EmailOrPhoneInput
                disabled={isOtpSent}
                contact={contact}
                setContact={setContact}
                placeholder="Enter your email or phone number"
              />
              {isOtpSent ? (
                <div className="my-4">
                  <p className="mb-1 md:text-base text-sm">Verification code</p>
                  <input
                    className="rounded-lg bg-white md:h-12 h-10 md:text-base text-sm px-2 outline-none border border-solid border-gray-200 w-full"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter the OTP sent to your phone."
                    maxLength={6}
                  />
                </div>
              ) : (
                <PasswordInput
                  required
                  forgot
                  password={password}
                  setPassword={setPassword}
                  placeholder="*****************"
                />
              )}
              <Button
                type="primary"
                onClick={onSubmit}
                size="large"
                className="bg-[#1A94EC] w-full text-white md:text-base text-sm"
              >
                {loading
                  ? "Processing..."
                  : isOtpSent
                  ? "Verify OTP"
                  : "Sign in"}
              </Button>
              {isOtpSent && (
                <div className="flex gap-4">
                  <Button
                    onClick={sendOtp}
                    size="large"
                    className="w-full my-2 md:text-base text-sm"
                  >
                    Resend Code
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOtpSent(false);
                    }}
                    size="large"
                    className="w-full my-2 md:text-base text-sm"
                  >
                    Change Number
                  </Button>
                </div>
              )}

              <div className="w-full flex justify-center items-center mt-12">
                <p className="text-[#ADADAD] md:text-lg text-sm">
                  Don’t have an account?{" "}
                  <Link to="/sign-up" className="text-[#1A94EC]">
                    Create an account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="absolute bottom-3 right-9 flex justify-center items-center">
          <img src={medW} className="h-6 mx-4" alt="" />
          <img src={MTNLogo} alt="mtn" className="h-6" />
        </div>
      </div>

      <div className="h-screen w-full lg:flex hidden justify-between items-center">
        <div
          className="h-full flex justify-center items-center w-[50%] bg-cover bg-[#1A94ECB5] bg-blend-overlay"
          style={{ backgroundImage: `url(${img})` }}
        >
          <Link to="/" className="absolute top-6 left-4">
            <img src={images} className="md:h-20 h-10" alt="mcare" />
          </Link>
          <h1 className="text-9xl font-bold text-white">Welcome</h1>
        </div>
        {isNewUser && isVerified ? (
          <div className="h-full w-[47%] flex flex-col justify-center items-center">
            <div className="w-[80%] my-4 ">
              <p className="text-xl font-medium">Personal Information</p>
            </div>
            <div className="w-[80%]">
              <CustomInput
                title="Full name"
                // eslint-disable-next-line
                style="md:h-12 h-10"
                placeholder="Enter your full name"
                value={name}
                onChangeText={setName}
              />
              <EmailInput
                title="Email (optional)"
                email={email}
                setEmail={setEmail}
                placeholder="Enter your email address."
              />
              <DobInput dob={dob} setDob={setDob} />
              <CustomPickerList
                title="Gender"
                options={["male", "female"]}
                value={gender}
                onValueChange={setGender}
              />
              <Button
                type="primary"
                size="large"
                onClick={onNewUserSave}
                className="bg-[#1A94EC] w-full text-white"
              >
                {loading ? "Saving..." : "Save"}
              </Button>

              <div className="w-full flex justify-center items-center mt-12">
                <p className="text-[#ADADAD] text-lg">
                  Don’t have an account?
                  <Link to="/sign-up" className="text-[#1A94EC] mx-2">
                    Create an account
                  </Link>
                </p>
              </div>
              <div className="absolute bottom-5 right-9 flex">
                <img src={med} className="h-8 mx-4" alt="" />
                <img src={MTNLogo} className="h-8" alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full w-[47%] flex flex-col justify-center items-center">
            <div className="w-[80%] my-4 ">
              <h1 className="text-6xl font-bold mb-4">Login</h1>
              <p className="text-xl text-gray-500 ml-2">
                Sign in to your account.
              </p>
            </div>
            <div className="w-[80%]">
              <EmailOrPhoneInput
                contact={contact}
                setContact={setContact}
                placeholder="Enter your email or phone number"
                maxLength={6}
              />

              {isOtpSent ? (
                <div className="my-4">
                  <p className="mb-1 md:text-base text-sm">Verification code</p>
                  <input
                    maxLength={6}
                    className="rounded-lg bg-white md:h-12 h-10 md:text-base text-sm px-2 outline-none border border-solid border-gray-200 w-full"
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter the OTP sent to your phone."
                  />
                </div>
              ) : (
                <PasswordInput
                  required
                  forgot
                  password={password}
                  setPassword={setPassword}
                  placeholder="*****************"
                />
              )}

              <Button
                type="primary"
                size="large"
                onClick={onSubmit}
                className="bg-[#1A94EC] w-full text-white"
              >
                {loading
                  ? "Processing..."
                  : isOtpSent
                  ? "Verify OTP"
                  : "Sign in"}
              </Button>
              {isOtpSent && (
                <div className="flex gap-4">
                  <Button
                    onClick={sendOtp}
                    size="large"
                    className="w-full my-2 md:text-base text-sm"
                  >
                    Resend Code
                  </Button>
                  <Button
                    onClick={() => {
                      setIsOtpSent(false);
                    }}
                    size="large"
                    className="w-full my-2 md:text-base text-sm"
                  >
                    Change Number
                  </Button>
                </div>
              )}

              <div className="w-full flex justify-center items-center mt-12">
                <p className="text-[#ADADAD] text-lg">
                  Don’t have an account?
                  <Link to="/sign-up" className="text-[#1A94EC] mx-2">
                    Create an account
                  </Link>
                </p>
              </div>
              <div className="absolute bottom-5 right-9 flex">
                <img src={med} className="h-8 mx-4" alt="" />
                <img src={MTNLogo} className="h-8" alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="recaptcha-container"></div>
      {contextHolder}
    </div>
  );
}
