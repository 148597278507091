import React from 'react';
import { Drawer } from 'antd';
import mcarehd from "../../../../assets/images/mcarehd.png";
import { useNavigate } from 'react-router-dom';


interface CustomDrawerProps {
  open: boolean;
  onClose: () => void;
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({ open, onClose }) => {
    const navigate = useNavigate();


  return (
    <Drawer title={ <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img src={mcarehd} alt="care" className="md:h-8 h-8 " />
  </div>
} onClose={onClose} open={open}>

    <div className=' flex  justify-start'>
      
      <button
          onClick={() => navigate("/sign-up")}
          className="bg-[#FFCC08] px-8 py-2 cursor-pointer rounded-full"
        >
          Register
        </button>
        </div>
    </Drawer>
  );
};

export default CustomDrawer;