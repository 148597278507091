import { Button, notification, Result } from "antd";
import React, { useState } from "react";
import Colors from "../../../constants/Colors";
import DataProvider from "../../../services";

export default function ChangePlan(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  const unsubscribed = async () => {
    try {
      const payload = {
        unsubscribeReason: "N/A",
        unsubscribeReasonList: ["Change Plan"],
      };

      setLoading(true);
      await DataProvider.unsubscribe(payload);
      props.setScreen(2);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return api["error"]({
        message: "Failed",
        description: "Failed, kindly try again later.",
      });
    }
  };

  return (
    <div className="flex justify-center items-center w-full min-h-[65vh]">
      <div className="md:w-[60%]">
        <Result
          status="warning"
          title="Confirm Change Plan"
          subTitle={`Changing your plan will remove you completely from your current Mcare benefits. This action cannot be undone.`}
          extra={[
            <Button
              style={{ color: Colors.primaryBlue }}
              onClick={() => props.setScreen(0)}
              key="back"
            >
              Go Back
            </Button>,
            <Button
              onClick={unsubscribed}
              style={{ backgroundColor: Colors.primaryBlue }}
              type="primary"
              key="confirm"
            >
              {loading ? "Changing..." : "I understand, Continue"}
            </Button>,
          ]}
        />
      </div>
      {contextHolder}
    </div>
  );
}
