import React, { useState } from "react";
import mcarehd from "../../../../assets/images/mcarehd.png";
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import Footer2 from "./footer2";
import Calltoaction from "./call-to-action";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();
  const [showFullPackage, setShowFullPackage] = useState(false);
  const [showOthers, setShowOthers] = useState(false);

  // Full package list
  const fullPackageList = [
    "Video-consultation",
    "Convenient Health Care",
    "E-prescriptions",
    "Medication Delivery",
    "Health Information Search Engine",
    "Chatbot",
    "Access to over 500 Health Providers",
    "Full Package Health Insurance",
    "Life and Death Cover",
    "Lower Consultation Fees",
    "Patient Safety & Privacy",
    "Worldwide Access",
    "Medication Cover",
    "Refill Cover",
    "Diagnostics Cover",
    "Dental Cover",
    "Lens & Frame Cover",
    "Ambulatory Services Cover",
    "Ante-Natal and Post-Natal with Delivery / Childbirth",
    "Maternity & Delivery / Childbirth Cover",
    "Intensive Care - ICU",
    "Surgery Cover",
    "Chronic Disease Cover",
    "Storage of Medical Records",
  ];

  // Others list
  const othersList = [
    "Video-consultation",
    "Convenient Health Care",
    "E-prescriptions",
    "Medication Delivery",
    "Health Information Search Engine",
    "Chatbot",
    "Access to over 500 Health Providers",
    "Full Package Health Insurance",
    "Life and Death Cover",
    "Lower Consultation Fees",
    "Patient Safety & Privacy",
    "Worldwide Access",
    "Medication Cover",
    "Refill Cover",
    "Diagnostics Cover",
    "Dental Cover",
    "Lens & Frame Cover",
    "Ambulatory Services Cover",
    "Ante-Natal and Post-Natal with Delivery / Childbirth",
    "Maternity & Delivery / Childbirth Cover",
    "Intensive Care - ICU",
    "Surgery Cover",
    "Chronic Disease Cover",
    "Storage of Medical Records",
  ];

  // Limit lists to 10 items when collapsed
  const visibleFullPackageList = showFullPackage
    ? fullPackageList
    : fullPackageList.slice(0, 10);
  const visibleOthersList = showOthers ? othersList : othersList.slice(0, 10);

  return (
    <div className="bg-gray-100">
      <div className="p-4 md:p-8 text-center">
        <h1 className="text-2xl md:text-4xl font-bold mb-4 md:mb-6">
          Convenient healthcare to the last mile
        </h1>
      </div>

      <div className="mb-6 flex flex-col md:flex-row justify-evenly px-4 space-y-4 md:space-y-0">
        {/* Full Package Section */}
        <div
          className={`${
            showFullPackage ? "" : "min-h-96"
          } rounded-3xl border bg-white shadow-lg p-4 w-full md:w-1/3`}
        >
          <div className="flex justify-center mb-6">
            <img
              src={mcarehd}
              alt="care"
              className="h-8 md:h-10 rounded-l-lg"
            />
          </div>

          <div className="justify-center">
            <ul className="font-semibold justify-evenly pl-4">
              {visibleFullPackageList.map((item, index) => (
                <li key={index} className="mb-2 flex items-center">
                  <FaCheck className="h-4 w-7 mr-2 text-green-500" />
                  {item}
                </li>
              ))}
            </ul>
          </div>

          {/* Full Package Toggle Button */}
          <div className="text-center mt-4">
            <button
              className="text-blue-500 font-semibold"
              onClick={() => setShowFullPackage(!showFullPackage)}
            >
              {showFullPackage ? "Show Less" : "Show More"}
            </button>
          </div>
        </div>

        {/* Others Section */}
        <div className="bg-white rounded-3xl shadow-lg p-4 w-full md:w-1/3">
          <div className="flex justify-center mb-6">
            <h1 className="text-xl md:text-2xl">Others</h1>
          </div>
          <ul className="font-semibold justify-evenly pl-4">
            {visibleOthersList.map((item, index) => (
              <li key={index} className="mb-2 flex items-center">
                {item.startsWith("Convenient") ||
                item.startsWith("Health Information Search Engine") ||
                item.startsWith("Chatbot") ||
                item.startsWith("Access to over 500 Health Providers") ||
                item.startsWith("Life and Death Cover") ||
                item.startsWith("Lower Consultation Fees") ||
                item.startsWith("Patient Safety & Privacy") ||
                item.startsWith("Worldwide Access") ||
                item.startsWith("Medication Cover") ||
                item.startsWith("Refill Cover") ||
                item.startsWith("Diagnostics Cover") ||
                item.startsWith("Dental Cover") ||
                item.startsWith("Lens & Frame Cover") ||
                item.startsWith("Ambulatory Services Cover") ||
                item.startsWith("Intensive Care - ICU") ||
                item.startsWith("Surgery Cover") ||
                item.startsWith("Chronic Disease Cover") ? (
                  <ImCross className="h-4 w-7 mr-2 text-red-500" />
                ) : (
                  <FaCheck className="h-4 w-7 mr-2 text-green-500" />
                )}
                {item}
              </li>
            ))}
          </ul>

          {/* Others Toggle Button */}
          <div className="text-center mt-4">
            <button
              className="text-blue-500 font-semibold"
              onClick={() => setShowOthers(!showOthers)}
            >
              {showOthers ? "Show Less" : "Show More"}
            </button>
          </div>
        </div>
      </div>

      <div className="justify-center flex mt-20">
        <button
          onClick={() => navigate("/sign-up")}
          className="bg-[#F7CB31] text-black font-bold py-2 md:py-4 px-10 md:px-24 text-lg md:text-xl rounded-lg hover:bg-yellow-400 transition duration-300"
        >
          Get Started
        </button>
      </div>

      <Calltoaction />
      <Footer2 />
    </div>
  );
}
