import { Button, Result, notification } from "antd";
import React, { useState } from "react";
import { useAppStore } from "../../../store";
import DataProvider from "../../../services";
import Colors from "../../../constants/Colors";
import { useNavigate } from "react-router-dom";

export default function Confirm(props: any) {
  const { plan, setUser } = useAppStore();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(0);

  const checking = async () => {
    try {
      setLoading(true);

      const { data } = await DataProvider.getUserSubscription();

      if (data.message && data.message === "Not a subscriber!") {
        setLoading(false);
        return api["warning"]({
          message: "Warning",
          description:
            "Payment initiation failed. Kindly go back and try again!",
        });
      } else if (data && data.status !== "active") {
        setLoading(false);
        setStatus(2);
        return;
        // return api["warning"]({
        //   message: "Warning",
        //   description:
        //     "Payment not confirmed. Kindly enter your pin to confirm on the popup or go back to try again.",
        // });
      }
      const result = await DataProvider.getProfile();
      setUser(result.data);
      setStatus(1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return api["error"]({
        message: "Error",
        description: "Payment confirmation failed. Kindly try again.",
      });
    }
  };

  return (
    <div className="flex justify-center items-center w-full min-h-[65vh]">
      {status === 0 && (
        <Result
          status="info"
          title="Payment has been initiated!"
          subTitle={`A popup of the payment will appear on your device. Kindly confirm with your pin to make payment. If payment popup delays, dial *170# to approve payment.`}
          extra={[
            <Button
              onClick={checking}
              style={{ backgroundColor: Colors.primaryBlue }}
              type="primary"
              key="confirm"
            >
              {loading ? "Checking..." : "Payment Completed"}
            </Button>,
            <Button
              style={{ color: Colors.primaryBlue }}
              onClick={() => props.setScreen(2)}
              key="back"
            >
              Go Back
            </Button>,
          ]}
        />
      )}
      {status === 1 && (
        <Result
          status="success"
          title={`Successfully Subscribed to Mcare ${plan?.description}`}
          subTitle="An email has been sent to kindly check and follow the steps to access benefits."
          extra={[
            <Button
              onClick={() => navigate("/main")}
              type="primary"
              key="home"
              style={{ backgroundColor: Colors.primaryBlue }}
            >
              Home
            </Button>,
            <Button onClick={() => props.setScreen(0)} key="mcare">
              Mcare
            </Button>,
          ]}
        />
      )}
      {status === 2 && (
        <Result
          status="info"
          title={`Your account is being processed for activation.`}
          subTitle="This may take up to an hour. A customer agent will contact you shortly for assistance."
          extra={[
            <Button
              onClick={() => navigate("/main")}
              type="primary"
              key="home"
              style={{ backgroundColor: Colors.primaryBlue }}
            >
              Go Home
            </Button>,
          ]}
        />
      )}
      {contextHolder}
    </div>
  );
}
