/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Checkbox, Form, Input, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataProvider from "../../services";
import _ from "lodash";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useAppStore } from "../../store";
import {
  MODAL_TYPES,
  useGlobalModalContext,
} from "../../context/global-modal-context";
import { useAppState } from "../../context/app-state-provider";
import LoadWrapper from "../elements/load-wrapper";

type NotificationType = "success" | "info" | "warning" | "error";

export default function AdditionalDetails(props: any) {
  const { showModal } = useGlobalModalContext();
  const { api, contextHolder } = useAppState();
  const { user } = useAppStore();
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const [loading, setLoading] = useState(false);
  const [deliveryData, setDeliveryData] = useState<any[]>([]);
  const [delivery, setDelivery] = useState({
    id: null,
    landmark: null,
    town: null,
  });
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);
  const [insuranceData, setInsuranceData] = useState<any[]>([]);
  const [insurance, setInsurance] = useState({
    id: null,
    provider: null,
  });
  const [deliveryMethods, setDeliveryMethods] = useState<any[]>([]);
  const [selectMethod, setMethod] = useState<number | null>(null);
  const [contact, setContact] = useState("");
  const [company, setCompany] = useState("");
  const [staffId, setStaffId] = useState("");
  const [value, setValue] = useState<"mc" | "csh" | "">("");
  const [mcare, setMcare] = useState<any>(null);
  // const [value, setValue] = useState<"ins" | "sta" | "mc" | "csh" | "">("");

  const getAddress = useCallback(async () => {
    try {
      await DataProvider.getDelivery().then(async (res: any) => {
        setDeliveryData(res.data);
      });

      await DataProvider.getInsurance().then(async (res: any) => {
        const data: any = _.sortBy(res.data, "provider");
        setInsuranceData(data);
      });

      if (user && user.phoneNumber) {
        form.setFieldValue("contact", user.phoneNumber);
        setContact(user.phoneNumber);
      }

      await DataProvider.deliveryMethods().then(async (res) => {
        const methods = res.data;
        localStorage.setItem("@type", JSON.stringify(methods));
        setDeliveryMethods(methods);
      });
    } catch (_) {}
  }, []);

  async function fetchAddress() {
    try {
      await DataProvider.getDelivery().then(async (res: any) => {
        setDeliveryData(res.data);
      });
    } catch (_) {}
  }

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  // const _submit = async () => {
  //   if (!hide && !insurance.provider) {
  //     return alert("Provide insurance details");
  //   }

  //   if (!selectMethod.key) {
  //     return alert("Provide type of delivery");
  //   }

  //   if (!delivery.town) {
  //     return alert("Provide delivery address");
  //   }

  //   if (contact.length < 10) {
  //     return alert("Provide contact details");
  //   }

  //   // const payload = {
  //   //   landmark: delivery.landmark,
  //   //   // image: data.imageURL,
  //   //   contact: contact,
  //   //   deliveryMode: selectMethod.id,
  //   //   newInsurance: hide ? null : insurance.id,
  //   //   newAddress: delivery.id,
  //   //   notes: note,
  //   //   company: company,
  //   //   staff_id: staffId,
  //   // };

  //   // const extras = {
  //   //   // imageURI: data.uri,
  //   //   delivery,
  //   //   insurance,
  //   //   mode: selectMethod,
  //   // };

  //   // const pl = JSON.stringify(payload);
  //   // const ex = JSON.stringify(extras);

  //   // router.push({
  //   //   pathname: "/prescription/summary",
  //   //   params: { pl, ex },
  //   // });
  // };

  function _Hide(e: CheckboxChangeEvent) {
    setHide(e.target.checked);
  }

  const getMcare = async () => {
    setLoading(true);
    try {
      const { data } = await DataProvider.getUserSubscription();
      if (data && data.message === "Not a subscriber!") {
        setLoading(false);
        setMcare(null);
        return;
      } else if (data && data.status !== "active") {
        setLoading(false);
        setMcare(null);
        return;
      }
      setMcare(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMcare(null);
    }
  };

  useEffect(() => {
    if (value === "mc") {
      getMcare();
    }
  }, [value]);

  function _onProceed() {
    const formX = form.getFieldsValue();
    if (value.length < 1) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Select a payment method!"
      );
    }

    if (!formX.deliveryMethod) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Delivery method is required!"
      );
    }

    if (selectMethod === 2 && !formX.address) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Kindly select your delivery address!"
      );
    }
    if (!formX.contact) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Phone number is required for delivery!"
      );
    }

    if (value === "mc" && (!mcare || mcare?.status !== "active")) {
      return openNotificationWithIcon(
        "warning",
        "Mcare",
        "You are not subscribed to any package!"
      );
    }

    const insurance = formX.insurance
      ? insuranceData.filter((item) => item.id === formX.insurance)
      : null;

    let address: any;
    if (formX.address) {
      address = deliveryData.find((item) => item.id === formX.address);
    }
    const deliveryMethod = deliveryMethods.filter(
      (item) => item.id === formX.deliveryMethod
    );

    delete formX.address;
    delete formX.deliveryMethod;
    delete formX.insurance;

    props.setPersist({
      ...props.persist,
      ...formX,
      address: address ? address : null,
      deliveryMethod: deliveryMethod[0],
      insurance,
    });
    props.setTab(2);
  }

  const openUserModal = () => {
    showModal(MODAL_TYPES.ADDRESS_MODAL, {
      title: "Add Delivery Address",
      confirmBtn: "Ok",
      fetch: () => fetchAddress(),
    });
  };

  const addressLabel = () => (
    <div className="w-full flex items-center justify-between">
      <span className="">Delivery address</span>
      <Button onClick={openUserModal} type="link">
        Add Address
      </Button>
    </div>
  );

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="lg:w-[40%] md:w-[70%] w-[90%] flex flex-col rounded-lg">
        <div className="">
          <h2 className="font-semibold text-lg">Additional Information</h2>
        </div>
        <Form form={form} name="basic" layout="vertical" autoComplete="off">
          <div className="my-4">
            <h2 className=" leading-9">How do you want to pay?</h2>
            <div className="flex"> 
              {/* <div className="flex mb-2 md:text-sm text-xs mr-2 items-center">
                <Checkbox
                  onChange={(e) =>
                    e.target.checked ? setValue("ins") : setValue("")
                  }
                  className="mr-2 md:text-sm text-xs"
                />{" "}
                Insurance
              </div> */}
              {/* <div className="flex mb-2 md:text-sm text-xs mr-2 items-center">
                <Checkbox
                  onChange={(e) =>
                    e.target.checked ? setValue("sta") : setValue("")
                  }
                  className="mr-2 md:text-sm text-xs"
                />{" "}
                Corporate
              </div> */}
              <div className="flex mb-2 md:text-sm text-xs mr-2 items-center">
                <Checkbox
                  onChange={(e) =>
                    e.target.checked ? setValue("mc") : setValue("")
                  }
                  className="mr-2 md:text-sm text-xs"
                />{" "}
                Mcare
              </div>
              <div className="flex mb-2 md:text-sm text-xs mr-2 items-center">
                <Checkbox
                  checked={value === "csh"}
                  onChange={(e) =>
                    e.target.checked ? setValue("csh") : setValue("")
                  }
                  className="mr-2 md:text-sm text-xs"
                />
                Cash
              </div>
            </div>
          </div>
          {value === "mc" && (
            <>
              {loading ? (
                <LoadWrapper
                  size="small"
                  tip="checking mcare package..."
                  loading={loading}
                >
                  <div className="border-2 bg-[#F0F9FF] rounded-lg md:h-16 h-12 border-[#3498db]"></div>
                </LoadWrapper>
              ) : !loading && !mcare ? (
                <div className="">
                  <p className="text-red-500 font-medium">
                    You are not subscribed to any package.
                  </p>
                </div>
              ) : !loading && mcare ? (
                <div className="border-2 bg-[#F0F9FF] rounded-lg p-2 border-[#3498db]">
                  <h4 className="text-gray-600">{mcare.mcare.description}</h4>
                  <div className="flex">
                    {mcare.mcare.offers.map((rea: any, idx: any) => (
                      <p className="mr-1 text-gray-500 text-sm">{rea.name}</p>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
          {/* {value === "ins" && (
            <Form.Item name="insurance" label="Insurance">
              <Select size="large" showSearch placeholder="Select a person">
                {insuranceData.map((v, index) => (
                  <Option key={index} value={v.id}>
                    <p>{v.town}</p>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )} */}

          <Form.Item name="deliveryMethod" label="Delivery method">
            <Select
              size="large"
              showSearch
              placeholder="Select delivery method"
              onChange={(itx) => setMethod(itx)}
            >
              {deliveryMethods.map((v, index) => (
                <Option key={index} value={v.id}>
                  <p>{v.description}</p>
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectMethod === 2 && (
            <div>
              {addressLabel()}
              <Form.Item name="address" label="">
                <Select
                  size="large"
                  showSearch
                  placeholder="Select delivery address"
                >
                  {deliveryData.map((v, index) => (
                    <Option key={index} value={v.id}>
                      <p>{v.town}</p>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}

          <Form.Item name="contact" label="Phone Number">
            <Input
              size="large"
              type="number"
              placeholder="Enter phone number"
            />
          </Form.Item>

          <Form.Item name="notes" label="Additional information">
            <Input
              size="large"
              placeholder="Additional information allergies, etc."
            />
          </Form.Item>

          <Form.Item>
            <Button
              onClick={_onProceed}
              type="primary"
              size="large"
              color="#0081D4"
              htmlType="submit"
              className="bg-[#0081D4] w-full"
            >
              Continue
            </Button>
          </Form.Item>

          <Button
            size="large"
            onClick={() => props.setTab(0)}
            className="w-full -mt-2"
          >
            Back
          </Button>
        </Form>
      </div>
      {contextHolder}
    </div>
  );
}
