import React from "react";
import mcare from "../../../../assets/images/logo.png";
import mtn from "../../../../assets/images/mtnwhite.png";
import mcarehd from "../../../../assets/images/mcarehd.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

export default function NavOne() {
  const navigate = useNavigate();
  return (
    <div className="h-20 w-full flex justify-between items-center md:px-12 px-8">
      <div className="h-full flex items-center gap-4">
        <img src={mcarehd} alt="care" className="md:h-8 h-4 rounded-l-lg" />
        <img src={mtn} alt="care" className="md:h-8 h-4 rounded-l-lg" />
        <img src={mcare} alt="care" className="md:h-8 h-4 rounded-l-lg" />
      </div>
      <div className="h-full md:flex items-center gap-8 hidden">
        <button
          onClick={() => navigate("/sign-in")}
          className="text-lg hover:bg-[#00000034] px-4 py-1 rounded"
        >
          Login
        </button>
        <button
          onClick={() => navigate("/sign-up")}
          className="text-lg hover:bg-[#00000034] px-4 py-1 rounded"
        >
          Register
        </button>
      </div>
      <div className="h-full flex items-center md:hidden cursor-pointer">
        <button className="text-gray-700">
          <GiHamburgerMenu size={20} />
        </button>
      </div>
    </div>
  );
}
