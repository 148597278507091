import React from 'react'
import { Link } from "react-router-dom";
import ios from "../../home-one/assets/ios.png";
import playstore from "../../home-one/assets/goo.png";

export default function Calltoaction() {
  return (
    <div className='w-full my-8 flex justify-center lg:px-40 px-4 md:px-12'>

    
     <div className="bg-blue-500 text-white py-10 rounded-2xl px-8 w-full">
      <div className=" mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="mb-6 md:mb-0">
          <h2 className="text-3xl font-bold">Download!</h2>
          <p className="text-lg mt-2">Download the Medpharma App for a better experience</p>
        </div>
        <div className="flex space-x-4 items-center">
            <Link
              to="https://apps.apple.com/gh/app/medpharma-app/id1477732400"
              target="_blank"
            >
              <img
                src={ios}
                alt="ios"
                className="cursor-pointer lg:h-12 sm:h-6 h-8 w-auto"
              />
            </Link>
            <Link
              to="https://play.google.com/store/search?q=medpharma&c=apps&hl=en"
              // to="http://onelink.to/vhzcxh"
              target="_blank"
            >
              <img
                src={playstore}
                alt="ios"
                className="cursor-pointer lg:h-12 sm:h-6 h-8"
              />
            </Link>
          </div>
      </div>
    </div>
    </div>
  )
}
