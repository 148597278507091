import React, { useEffect, useState } from "react";
import Steps from "../../components/elements/steps";
import { Stepx } from "../../utils/data";
import mtn from "../../assets/images/mtn.png";
import { useAppStore } from "../../store";
import PhoneNumberInput from "../../components/form/phone-number";
import { Button, Spin, notification } from "antd";
import Colors from "../../constants/Colors";
import { useNavigate } from "react-router-dom";
import { convertToInternationalFormat } from "../../utils";
import DataProvider from "../../services";
import images from "../../assets/images/poweredbymtn.png";
import axios from "axios";

export default function Billing() {
  const [api, contextHolder] = notification.useNotification();
  const { plan, user } = useAppStore();
  const navigate = useNavigate();
  const [number, setNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!user) navigate("/sign-up");
  }, [user, navigate]);

  async function onSubmit() {
    if (!number || number.length > 10 || number.length < 10) {
      return api["warning"]({
        message: "Warning",
        description: "Phone number is required.",
      });
    }

    if (!number.startsWith("0")) {
      return api["warning"]({
        message: "Warning",
        description: "Phone number must start with 0.",
      });
    }

    const payload = {
      mcareId: plan?.id,
      msisdn: convertToInternationalFormat(number),
    };

    try {
      setLoading(true);
      const { data } = await DataProvider.requestSubscription(payload);
      const tracking = localStorage.getItem("@tracking");
      if (tracking) {
        const payload = await JSON.parse(tracking);
        const load = await DataProvider.updateTracking({
          source: payload.source,
          campaign: payload.campaign,
          trackingId: payload.trackingId,
          subscriptionId: data.id,
        });
        if (load.data) {
          localStorage.setItem("@tracking", JSON.stringify(load.data));
        }
      }
      setLoading(false);
      navigate("/plan/comfirm-pay-00725");
    } catch (error: any) {
      setLoading(false);
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const errorMessage = error.response.data.message;
          return api["error"]({
            message: "Failed",
            description:
              errorMessage === "Offer does not exist"
                ? "The subscription offer selected does not exist."
                : errorMessage === "Duplicate Request"
                ? "Too many requests, kindly try again 10 minutes later."
                : errorMessage,
          });
        } else {
          return api["error"]({
            message: "Failed",
            description:
              error.message === "Offer does not exist"
                ? "The subscription offer selected does not exist."
                : error.message === "Duplicate Request"
                ? "Too many requests, kindly try again 10 minutes later."
                : error.message,
          });
        }
      } else {
        return api["error"]({
          message: "Failed",
          description: "Subscription payment failed, try again.",
        });
      }
    }
  }

  return (
    <div className="min-h-screen w-full flex flex-col justify-start items-center py-12">
      <div className="mt-4 mb-5 lg:w-[60%] md:w-[80%] w-full flex flex-col justify-center items-center">
        <Steps current={2} items={Stepx} />
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 w-full my-5 p-4">
          <div className="border border-solid border-gray-200 p-4">
            <div className="bg-black w-full h-20 flex justify-center mb-4 items-center">
              <h1 className="text-white text-2xl font-semibold">
                {plan?.description}
              </h1>
            </div>
            <div className="py-2 border-b flex justify-between items-center">
              <p className="md:text-base text-sm">Mcare {plan?.description}</p>
              <p className="md:text-base text-sm">GH¢{plan?.price}/mo</p>
            </div>
            <div className="py-2 border-b flex justify-between items-center">
              <p className="md:text-base text-sm">Period</p>
              <p className="md:text-base text-sm">1 month</p>
            </div>
            <div className="pt-2 flex justify-between items-center">
              <p className="md:text-base text-sm">Total</p>
              <p className="md:text-base text-sm">GH¢{plan?.price}</p>
            </div>
            <div className="flex justify-center mt-16 ">
              <img
                src={images}
                alt="care"
                className="md:h-20 h-10 rounded-l-lg"
              />
            </div>
          </div>
          <div className="border border-solid border-gray-200 p-4">
            <div className="bg-gray-100 w-full h-16 flex justify-center items-center">
              <h3 className="font-medium text-base">Mobile Money</h3>
            </div>
            <div className="">
              <PhoneNumberInput
                required
                title="Enter Phone Number"
                value={number}
                onChangeText={setNumber}
                placeholder="eg:0240005678"
              />

              <div>
                <select
                  name="network"
                  id=""
                  value="mtn"
                  onChange={() => {}}
                  className="rounded-lg bg-white px-2 outline-none border border-solid border-gray-200 w-full h-12 text-base"
                >
                  <option value="mtn" className="flex items-center">
                    <img src={mtn} alt="mtn" className="h-7" />
                    MTN
                  </option>
                </select>
                <p className="text-gray-500 md:text-sm text-xs my-2">
                  By Clicking “Proceed with payment” you agree to the terms of
                  service of the company
                </p>
              </div>

              <div className="w-full mt-4">
                <Button
                  onClick={onSubmit}
                  size="large"
                  style={{ backgroundColor: Colors.primary, color: "white" }}
                  className="w-full"
                >
                  Proceed with payment
                </Button>
                <Button
                  onClick={() => navigate("/pick-plan")}
                  size="large"
                  className="w-full mt-4"
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spin spinning={loading} fullscreen />
      {contextHolder}
    </div>
  );
}
