import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/fonts/index.css";
import reportWebVitals from "./reportWebVitals";
import "firebase/auth";

import App from "./App";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

const rootContainer = document.getElementById("root") as HTMLElement;
let container;

container = document.createElement("div");
container.id = "amazon-chime-sdk-widget-container";
rootContainer.appendChild(container);

const root = ReactDOM.createRoot(rootContainer);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
