/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import EmailInput from "../form/email-input";
import PasswordInput from "../form/password-input";
import { Button, Radio, Spin } from "antd";
import { Link } from "react-router-dom";
import CustomInput from "../form/custom-input";
import { LoadingOutlined } from "@ant-design/icons";
import Colors from "../../constants/Colors";
import PhoneNumberInput from "../form/phone-number";
import { formatTime } from "../../utils";

export default function UserRegister(props: any) {
  const [isActive, setIsActive] = useState(true);
  const [time, setTime] = useState(120); // 2 minutes in seconds

  const startCountdown = () => {
    setIsActive(true);
    setTime(120); // Reset time to 2 minutes
  };

  useEffect(() => {
    if (!isActive) return;
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          setIsActive(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isActive]);

  // Automatically start countdown on component mount
  useEffect(() => {
    startCountdown();
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center mt-4 ">
      <div className="w-[80%] flex flex-col justify-center items-center">
        <h1 className="md:text-3xl text-xl font-bold leading-10">
          Create Your Account
        </h1>
        <p className="text-sm text-gray-500">
          Create an account to get started today.
        </p>
      </div>

      <div className="w-[80%] mt-4">
        {props.signupMethod === "phone" && props.isOtpSent ? (
          <div className="w-full">
            <form className="my-4 w-full">
              <p className="mb-2 md:text-base text-sm">
                A verification code has been sent to your phone.
              </p>
              <input
                maxLength={6}
                className="rounded-lg bg-white md:h-12 h-10 md:text-base text-sm px-2 outline-none border border-solid border-gray-200 w-full"
                type="text"
                value={props.otp}
                onChange={(e) => props.setOtp(e.target.value)}
                placeholder="Enter the OTP sent to your phone."
              />
              <div className="">
                <Button
                  type="primary"
                  onClick={props.onSubmit}
                  size="large"
                  className="bg-[#1A94EC] w-full text-white md:text-base text-sm my-4"
                >
                  {props.loading ? "Processing..." : "Verify OTP"}
                </Button>
                <div className="flex md:flex-nowrap flex-wrap gap-4">
                  <Button
                    disabled={isActive}
                    onClick={props.sendOtp}
                    size="large"
                    className="w-full my-2 md:text-base text-sm"
                  >
                    {isActive
                      ? `Resend code in ${formatTime(time)} minutes`
                      : "Resend Code"}
                  </Button>
                  <Button
                    onClick={() => {
                      props.setIsOtpSent(false);
                    }}
                    size="large"
                    className="w-full my-2 md:text-base text-sm"
                  >
                    Change Number
                  </Button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="w-full">
            <div className="w-full">
              <p className="mb-1 md:text-base text-sm">Create account with:</p>
              <Radio.Group
                // disabled={true}
                onChange={(e) => props.handleSignupMethodChange(e.target.value)}
                value={props.signupMethod}
                className="flex items-center"
              >
                <Radio value="email" id="email" className="">
                  Email
                </Radio>
                <Radio value="phone" id="phone" className="">
                  Phone Number
                </Radio>
              </Radio.Group>
            </div>

            {props.signupMethod === "email" && (
              <div className="w-full">
                <CustomInput
                  title="Full name"
                  placeholder="Enter your full name"
                  value={props.name}
                  onChangeText={props.setName}
                />
                <EmailInput
                  email={props.email}
                  setEmail={props.setEmail}
                  placeholder="Enter your email address."
                />
                <PasswordInput
                  required
                  password={props.password}
                  setPassword={props.setPassword}
                  placeholder="*****************"
                />

                <CustomInput
                  title="Referral Code"
                  placeholder="Enter  referral code"
                  value={props.referralCode}
                  onChangeText={props.setReferralCode}
                />
                <Button
                  type="primary"
                  size="large"
                  onClick={props.onSubmit}
                  className="bg-[#1A94EC] w-full md:text-base text-sm"
                >
                  {props.loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ color: Colors.white, fontSize: 24 }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Sign up"
                  )}
                </Button>

                <div className="w-full flex justify-center items-center mt-1">
                  <p className="text-[#ADADAD] text-sm">
                    Have an account?
                    <Link to="/sign-in" className="text-[#1A94EC]">
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            )}

            {props.signupMethod === "phone" && (
              <div className="w-full">
                <CustomInput
                  title="Full name"
                  placeholder="Enter your full name"
                  value={props.name}
                  onChangeText={props.setName}
                />
                <PhoneNumberInput
                  required
                  title="Phone Number"
                  value={props.phoneNumber}
                  onChangeText={props.setPhoneNumber}
                  placeholder="0240005678"
                  notes="This number will also be used for your subscription. it is advisable to use a number with MoMo."
                />
                <PasswordInput
                  required
                  password={props.password}
                  setPassword={props.setPassword}
                  placeholder="*****************"
                />

                <CustomInput
                  title="Referral Code"
                  placeholder="Enter  referral code"
                  value={props.referralCode}
                  onChangeText={props.setReferralCode}
                />
                <Button
                  type="primary"
                  size="large"
                  onClick={props.onSubmit}
                  className="bg-[#1A94EC] w-full md:text-base text-sm"
                >
                  {props.loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ color: Colors.white, fontSize: 24 }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Sign up"
                  )}
                </Button>

                <div className="w-full flex justify-center items-center mt-1">
                  <p className="text-[#ADADAD] text-sm">
                    Have an account?{" "}
                    <Link to="/sign-in" className="text-[#1A94EC]">
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
