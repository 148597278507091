import React from "react";
import HeaderOne from "./components/header-one";

export default function HomeOne() {
  return (
    <div>
      <HeaderOne />
    </div>
  );
}
