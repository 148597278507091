import React, { useState } from "react";
import NavOne from "./nav-one";
import { IoMdCheckboxOutline } from "react-icons/io";
import doc from "../assets/doc.png";
import layer1 from "../assets/Layer4.png";
import layer3 from "../assets/layer3.png";
import layer2 from "../assets/layer2.png";
import layer9 from "../assets/Layer9.png";
import layer4 from "../assets/Layer5.png";
import { useNavigate } from "react-router-dom";

export default function HeaderOne() {
  const navigate = useNavigate();
  const [date, setDate] = useState<string | undefined>(undefined);
  const [time, setTime] = useState<string | undefined>(undefined);
  const [symptoms, setSymptoms] = useState<string | undefined>(undefined);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
      date,
      time,
      symptoms,
    };
    localStorage.setItem("@Book", JSON.stringify(payload));
    navigate("/sign-up");
  };

  return (
    <div className="bg-[#FFCC08] md:h-screen min-h-screen">
      <NavOne />
      <div className="min-h-[70vh] grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 justify-between items-center lg:px-16 px-5 mt-4">
        <div className="p-4">
          <h1 className="xl:text-7xl lg:text-6xl md:text-6xl sm:text-5xl text-4xl text-white font-bold">
            How are you today?
          </h1>
          <div className="my-4">
            <ul className="gap-3">
              {services.map((i, idx) => (
                <li
                  className="flex text-black md:text-lg text-sm font-medium gap-2 items-center"
                  key={idx}
                >
                  <IoMdCheckboxOutline className="text-white" /> {i}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="xl:h-full lg:h-[70%] lg:flex hidden h-full">
          <img src={doc} alt="" className="" />
        </div>
        <div className="p-4">
          <form
            action=""
            onSubmit={handleSubmit}
            className="flex flex-col gap-4"
          >
            <textarea
              required
              value={symptoms}
              onChange={(e) => setSymptoms(e.target.value)}
              name="symptoms"
              id=""
              placeholder={`Enter your symptoms, e.g. "I have a headache nausea."`}
              className="w-full h-44 p-3 outline-none rounded-lg"
            ></textarea>
            <div className="flex md:flex-nowrap flex-wrap justify-between gap-4">
              <select
                required
                value={date}
                onChange={(e) => setDate(e.target.value)}
                name="date"
                id="date"
                className="text-gray-500 rounded-full px-4 py-3 outline-none cursor-pointer w-full flex justify-center"
              >
                {dates.map((v, index) => (
                  <option key={index} value={v}>
                    <p className="text-xs">{v}</p>
                  </option>
                ))}
              </select>
              <select
                required
                value={time}
                onChange={(e) => setTime(e.target.value)}
                name="time"
                id="date"
                className="text-gray-500 rounded-full px-4 py-3 outline-none cursor-pointer w-full"
              >
                {times.map((v, index) => (
                  <option key={index} value={v}>
                    <p className="text-xs">{v}</p>
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="px-4 py-3 my-4 bg-white rounded-full w-full text-lg font-semibold"
            >
              Book Appointment
            </button>
          </form>
        </div>
      </div>
      <div className="w-full grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 justify-center items-center gap-12 pt-10 bg-white md:rounded-t-[70px] rounded-t-[40px] min-h-60 px-16">
        {diagnosis.map((i, index) => (
          <div
            key={index}
            className="flex flex-col h-64 justify-center items-center"
          >
            <div
              className="rounded-3xl h-[95%] flex justify-center items-center w-full border bg-[#19AB56] overflow-hidden"
              style={{ background: i.bg }}
            >
              <img src={i.img} alt="" className="h-56 " />
            </div>
            <p className="my-2">{i.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

const dates = [
  "24-09-2024",
  "25-09-2024",
  "26-09-2024",
  "27-09-2024",
  "28-09-2024",
  "29-09-2024",
  "30-09-2024",
  "01-10-2024",
  "02-10-2024",
  "03-10-2024",
  "04-10-2024",
];
const times = [
  "8:30am to 8:45am",
  "8:50am to 9:05am",
  "9:10am to 9:25am",
  "9:30am to 9:45am",
  "9:50am to 10:05am",
  "10:10am to 10:25am",
  "10:30am to 10:45am",
  "10:50am to 11:05am",
  "11:10am to 11:25am",
  "11:30am to 11:45am",
  "11:50am to 12:05pm",
  "12:10pm to 12:25pm",
  "12:30pm to 12:45pm",
];

const services = [
  "Online Consultations",
  "E-Prescriptions",
  "Health Information",
  "Medication Delivery",
  "Medical Records",
  "Chatbot",
  "Full Package Medical Insurance",
  "Trauma, Hospitalization and Life",
];

const diagnosis = [
  {
    name: "Chronics",
    bg: "#19AB56",
    img: layer1,
  },
  {
    name: "Malaria",
    bg: "#FFA3BC",
    img: layer3,
  },
  {
    name: "Joints and Pain",
    bg: "#FFCB05",
    img: layer2,
  },
  {
    name: "PregCare",
    bg: "#3388CB",
    img: layer4,
  },
  {
    name: "Child Care",
    bg: "#36D578",
    img: layer9,
  },
];
